<template>
  <div>
    <b-card no-body class="mb-0">
      <Filters
        :status-filter.sync="statusFilter"
        :role-filter.sync="roleFilter"
      />
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ localize["table_show"] }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Table Control -->
      <b-table
        ref="refUserListTable"
        :items="fetchDataTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        hover
        primary-key="id"
        @row-clicked="handleRowClicked"
      >
        <template #empty>
          <div v-if="state.loading" class="text-center my-2">
            <p>{{ localize["table_loading"] }}</p>
          </div>
          <div
            v-if="!state.loading && totalRows === 0"
            class="text-center my-2"
          >
            {{ localize["table_empty"] }}
          </div>
        </template>
        <!-- Custom Column -->
        <template #cell(status)="{ item }">
          <div class="text-center">
            <b-badge
              pill
              :variant="`${resolveUserStatus(item.status)}`"
              class="text-capitalize"
            >
              {{ UserStatusParse[item.status] }}
            </b-badge>
          </div>
        </template>
        <template #cell(roleId)="{ item }">
          <div class="text-center">
            <b-badge
              pill
              :variant="`${resolveUserRole(item.roleId)}`"
              class="text-capitalize"
            >
              {{ RoleInfoParse[item.roleId] }}
            </b-badge>
          </div>
        </template>
        <template #cell(createdDate)="{ item }">
          <div class="text-wrap" style="width: 150px">
            {{ customTime(item.createdDate) }}
          </div>
        </template>
      </b-table>

      <!-- Pagination < Pre/Next > -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SortByEnum } from "@/@core/utils/enums/sort-by.enum";
import { UserTab } from "@/@core/utils/enums/tab.enum";
import { RoleInfoParse, UserStatusParse } from "@/@core/utils/enums/user.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { customTime } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import Filters from "./Filters.vue";
import useUsers from "./useUsers";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BButton,
    Filters,
    BBadge,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      state: (s) => {
        return s.apps.users.userList;
      },
    }),
  },
  methods: {
    ...mapActions({
      getUsers: "users/userList/getUsers",
    }),

    async fetchDataTable() {
      const data = await this.getUsers({
        search: this.searchQuery,
        limit: this.perPage,
        page: this.currentPage,
        status: this.statusFilter,
        roleId: this.roleFilter,
        order: this.sortBy ?? undefined,
        by: this.sortBy
          ? this.isSortDirDesc
            ? SortByEnum.DESC
            : SortByEnum.ASC
          : undefined,
      });
      this.totalRows = this.state.totalPage;
      return data;
    },
    handleRowClicked(item, index, event) {
      this.$router.push({
        name: "user-sub-menu",
        params: { userId: item.userId },
        query: { tab: UserTab.Info },
      });
    },
  },
  mounted() {
    this.refUsers = this.refUserListTable;
  },
  setup() {
    return {
      ...useTable(),
      ...useUsers(),
      ...useToastHandler(),
      localize,
      UserStatusParse,
      RoleInfoParse,
      customTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
