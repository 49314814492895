import {
  RoleEnum,
  RoleInfoParse,
  UserStatusEnum,
} from "@/@core/utils/enums/user.enum";
import { customTime } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
// Notification

export default function useUsers() {
  const statusFilter = ref(null);
  const roleFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    { key: "userId", label: "Id" },
    { key: "username", label: "Tên người dùng" },
    { key: "email", label: "Email" },
    { key: "fullName", label: "Họ Tên" },
    {
      key: "roleId",
      label: "Vai trò",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "status",
      label: "Trạng Thái",
      thClass: "text-center",
    },
    {
      key: "createdDate",
      label: "Ngày tạo",
      sortable: true,
      formatter: (value) => {
        return customTime(value);
      },
    },
  ];

  const resolveUserStatus = (status) => {
    switch (status) {
      case UserStatusEnum.Deleted:
        return "danger";
      case UserStatusEnum.Active:
        return "success";
    }
  };

  const resolveUserRole = (roleId) => {
    switch (roleId) {
      case RoleEnum.SuperAdmin:
        return "primary";
      case RoleEnum.Admin:
        return "warning";
      case RoleEnum.User:
        return "secondary";
      case RoleEnum.CustomerSupport:
        return "info";
    }
  };

  const statusOptions = [
    { label: "Active", value: 0 },
    { label: "Deleted", value: 1 },
  ];

  const roleOptions = [
    { label: RoleInfoParse[RoleEnum.Admin], value: RoleEnum.Admin },
    { label: RoleInfoParse[RoleEnum.User], value: RoleEnum.User },
    {
      label: RoleInfoParse[RoleEnum.CustomerSupport],
      value: RoleEnum.CustomerSupport,
    },
  ];
  watch([statusFilter, roleFilter], () => {
    refUsers.value.refresh();
  });

  return {
    tableColumns,
    statusOptions,
    roleOptions,
    statusFilter,
    roleFilter,
    sortBy,
    isSortDirDesc,
    refUsers,
    resolveUserStatus,
    resolveUserRole,
  };
}
