<template>
  <b-card no-body class="mb-0">
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Trạng thái</label>
          <v-select
            :value="statusFilter"
            :options="statusOptions"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col>
          <label>Vai trò</label>
          <v-select
            :value="roleFilter"
            :options="roleOptions"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2"></b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-end justify-content-end"
        >
          <b-link :to="{ name: 'user-signup' }" :disabled="!isRoleSuperAdmin()">
            <b-button
              class="cursor-pointer"
              size="md"
              variant="primary mr-1"
              :disabled="!isRoleSuperAdmin()"
            >
              <span class="ml-25 align-middle">Tạo tài khoản</span>
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { isRoleSuperAdmin } from "@/helpers/permission";
import { numberProp } from "@/helpers/props";
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BLink,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useUsers from "./useUsers";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BLink,
  },
  props: {
    statusFilter: numberProp(),
    roleFilter: numberProp(),
  },

  setup() {
    const { statusOptions, roleOptions } = useUsers();

    return {
      statusOptions,
      roleOptions,
      isRoleSuperAdmin,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
